<template>
  <!-- border: $clr-primary solid 3px -->
  <div>
    <v-card
      v-if="isCollectionExcludedForCurrentOrganization"
      elevation="5"
      :class="[
        'my-4',
        'collection-card',
        { 'currently-active': collection.currentlyActive },
      ]"
    >
      <v-card-text class="pb-0 pt-1">
        <div class="d-flex width-100 justify-space-between headline-container">
          <div>
            <span class="headline font-weight-bold">
              <v-icon>{{ mdiTableRemove }}</v-icon>
              {{ collection.name }}
            </span>
            <span>{{ collectionDisplayType }}</span>
            <span v-if="this.displayedDate && this.displayedDate.name">, {{ this.displayedDate.name }}</span>
          </div>
          <div>
            <a
              class="text-button"
              @click="handleDuplicationRequest"
            >
              <v-icon color="primary" class="mr-1">{{ mdiContentDuplicate }}</v-icon>
              Duplizieren
            </a>
          </div>
        </div>
      </v-card-text>
    </v-card>
    <v-card
      v-else
      elevation="5"
      :class="[
        'my-4',
        'collection-card',
        { 'currently-active': collection.currentlyActive },
      ]"
    >
      <v-card-text class="pb-0 pt-1">
        <template v-if="this.collection.targets">
          <div style="margin-left: -10px; margin-right: -10px; width:calc(100% + 20px)">
            <v-chip
              v-for="target in filteredCollectionTargetsIncluded"
              v-bind:key="target.key"
              class="mr-2 mt-2 mb-2"
              style="background: #ffffff"
              label
              :disabled="!(deviceFilter === 'all' || target === deviceFilter)"
            >
              <device-icon size="20" class="mr-2" :device-class="target"></device-icon>
              <template v-if="target === 'WA' && !collection.hasPassed && collection.currentlyActive && relevantDevices.has('john')">
                <a :href="kollekteAppUrl" target="_blank">
                  {{ deviceResolver(target) }}
                </a>
              </template>
              <template v-else-if="target === 'WS' && !collection.hasPassed && collection.currentlyActive && relevantDevices.has('bart')">
                <a :href="spendeAppUrl" target="_blank">
                  {{ deviceResolver(target) }}
                </a>
              </template>
              <template v-else>
                {{ deviceResolver(target) }}
              </template>
            </v-chip>
              <v-chip
              v-for="target in filteredCollectionTargetsExcluded"
              v-bind:key="target.key"
              class="mr-2 mt-2 mb-2"
              style="background: #ffffff"
              label
              :disabled="true"
            >
              <device-icon size="20" class="mr-2" :device-class="target"></device-icon>
              {{ deviceResolver(target) }}
              </v-chip>
              <v-btn
              v-if="!collection.currentlyActive && !collection.hasPassed && dialog && shouldShowDetailsButton"
              style="float:right; margin-top:0.5em"
              color="secondary-text"
              @click.native.stop="dialog(collection)"
              text
            >
              Details
            </v-btn>
            <v-btn
              v-else-if="dialog && shouldShowDetailsButton"
              style="float:right; margin-top:0.5em"
              @click.native.stop="dialog(collection)"
              icon
            >
              <v-icon color="primary">{{ mdiInformation }}</v-icon>
            </v-btn>

          </div>
        </template>
      </v-card-text>
      <v-card-title class="pt-0">
        <div class="d-flex width-100 justify-space-between headline-container">
          <div class="headline font-weight-bold">
            <v-icon
              v-if="isCollectionPlanGroupParent"
              color="black"
            >{{ mdiFormatListGroup }}
            </v-icon>
            {{ collection.name }}
          </div>
          <v-tooltip
            v-if="!isCollectionPlanGroupParent && isEligibleForCollectionPlanGroup && !isCollectionPlanGroupCandidate && FEATURE_FLAG_KODI_392_SHOW_COLLECTION_PLAN_GROUP_EDITOR"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click="markAsCollectionGroupCandidate"
              >
                <v-icon>{{ mdiFormatListGroupPlus }}</v-icon>
              </v-btn>
            </template>
          Zur Sammlungsgruppe hinzufügen
          </v-tooltip>
          <v-tooltip
            v-else-if="isEligibleForCollectionPlanGroup && isCollectionPlanGroupCandidate"
            bottom
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click="unmarkAsCollectionGroupCandidate"
              >
                <v-icon>{{ mdiCheckboxMarkedCirclePlusOutline }}</v-icon>
              </v-btn>
            </template>
            Aus Sammlungsgruppe entfernen
          </v-tooltip>
        </div>
      </v-card-title>
      <v-card-subtitle class="fontstyle">
        <!-- Sammlungsart -->
        <span>{{ collectionDisplayType }}</span>
        <span v-if="this.displayedDate && this.displayedDate.name">, {{ this.displayedDate.name }}</span>
        <br>
        <span class="organization-level-icon" v-if="!collection.locationObj"><!-- FIXME Alignment -->
          <template v-if="collection.organization && allOrganizations[collection.organization] && allOrganizations[collection.organization] !== undefined">
            <organization-icon :organization="allOrganizations[collection.organization]" size="25" color="rgba(0, 0, 0, 0.87)"></organization-icon>
          </template>
          <span class="mdi mdi-star-outline" v-else/>
        </span>
        <!-- Vererbung -->
        <span>Geltungsbereich: </span>
        <span v-if="collection.locationObj">
          <v-icon color="primary">{{ mdiMapMarker }}</v-icon>
          {{ collection.locationObj.name }}
        </span>
        <span
          v-else-if="collection.organization && collection.organization !== currentOrganization.url"
        >
          {{ allOrganizations[collection.organization].name }}
        </span>
        <span v-else>{{ collection.inherit ? 'diese Organisation und alle Unterorganisationen' : 'diese Organisation' }}</span>
        <div v-if="collection.excludedFor[organization.url].length">
          <v-icon>{{ mdiTableRemove }}</v-icon> auf der aktuellen Organisationsebene ausgeblendet/abgewählt
          <template v-if="!inactiveForAllTargets">
          für
            <span v-for="(target, index) in collection.excludedFor[organization.url]" v-bind:key="'excluded_'+target"
            ><span v-if="index > 0">, </span
            >{{deviceResolver(target)}}</span>
          </template>
        </div>

      </v-card-subtitle>
      <v-card-text class="fontstyle pb-0" v-if="!inactiveForAllTargets">
        <div v-if="collection.inactive" class="locked-badge inactive">
          (Eintrag gesperrt)
        </div>
        <template v-if="this.moreInfos">
          <div class="mb-2">
            <div>
              <v-icon color="primary">{{ mdiClockStart }}</v-icon> {{ this.colStartDate }} Uhr -
              <span v-if="this.colEndDate === null">unbegrenzt</span>
              <span v-else><v-icon color="primary">{{ mdiClockEnd }}</v-icon> {{ this.colEndDate }} Uhr</span>
              <template v-if="collection.currentlyActive">
                <span v-if="collection.end" class="float-right locked-badge active">
                  <span :title="collection.end"
                    >Noch {{ relEndTime }} aktiv</span
                  >
                </span>
              </template>
            </div>
            <v-row no-gutters v-if="colPercentageComplete !== null">
              <v-col cols="12" md="6">
                <v-progress-linear class="mt-2" :value="colPercentageComplete"></v-progress-linear>
              </v-col>
            </v-row>
          </div>
        </template>
        <div v-else-if="collection.hasPassed" class="locked-badge past">
          Sammlung abgeschlossen
        </div>
        <v-expand-transition>
          <div v-if="showPrioChooser">
            <v-card elevation="0">
              <h4 v-if="this.deviceFilter === 'SP'" class="pb-0 mb-0">Reihenfolge festlegen</h4>
              <h4 v-else class="pb-0 mb-0">Priorität festlegen</h4>
              Weisen Sie dieser Sammlung eine <template v-if="this.deviceFilter === 'SP'">Reihenfolge</template><template v-else>Priorität</template> zu, um die Anzeige auf<template v-if="this.deviceFilter === 'SP'"> der</template> <b>{{ this.deviceResolver(this.deviceFilter) }}</b> zu sortieren. <a @click="prioInfoDialog = true">Was bedeutet das?</a>
              <div class="d-flex flex-row">
                <v-chip-group
                  v-model="prioSelection"
                  active-class="primary"
                  class="mb-4 mt-1"
                  mandatory
                  column
                >
                  <v-chip
                    v-for="tag in (this.deviceFilter === 'SP' ? [1, 2, 3, 'keine'] : [1, 2, 3, 4, 5, 6, 7, 8, 9])"
                    :key="tag"
                    :label="deviceFilter === 'SP'"
                    :outlined="deviceFilter === 'SP'"
                  >
                    {{ tag }}
                  </v-chip>
                </v-chip-group>
                <v-btn
                  v-if="priority !== prioSelection"
                  color="primary"
                  class="ml-4 mt-2"
                  @click.stop="savePrioExtension"
                >
                  Speichern
                </v-btn>
                <v-btn
                  v-if="priority !== prioSelection"
                  color="primary"
                  text
                  class="mt-2"
                  :loading="prioLoader"
                  @click="prioSelection = priority"
                >
                  Zurücksetzen
                </v-btn>
              </div>
              <div v-if="false">
                <v-btn
                  v-for="ext in this.collection.extensions"
                  v-bind:key="ext.key"
                  @click.stop="deletePrioExtensionObject(ext.id)">
                  lösche {{ ext.device_class + ' - ' + ext.id }}
                </v-btn>
              </div>
            </v-card>
          </div>
        </v-expand-transition>
      </v-card-text>
      <v-card-actions class="ma-0 pa-0" v-if="showButtons">
        <div class="card-actions">
          <a
            v-if="hasPermission(PERM_TYPE_READ_OTHER) && (collection.currentlyActive || collection.hasPassed)"
            class="black-link text-button"
            @click.stop.prevent="openEvaluationDialog(collection.url)"
          >
            <v-icon class="mr-1" color="primary">{{ mdiTable }}</v-icon>
            Auswertung
          </a>
          <a
            v-if="allowCashPayments && !collection.location && !isArchived"
            class="black-link text-button"
            @click.stop.prevent="cashCollectionDialog = true"
          >
            <v-icon class="mr-1" color="primary">$vuetify.icons.cashCollection</v-icon>
            Barsammlung
          </a>
          <template
            v-if="
              ((hasSpendeApp && this.relevantDevices.has('bart')) || (hasKollekteApp && this.relevantDevices.has('john')))
            "
          >
            <a
              v-if="!collection.hasPassed"
              class="black-link  text-button"
              @click.stop="qrInfoDialog = true"
            >
              <v-icon class="mr-1" color="primary">{{ mdiQrcode }}</v-icon>
              Direktlink / QR-Code
            </a>

            <a
              v-if="hasPermission(READ_CPLAN) && notificationContacts.length > 0 && !collection.hasStarted"
              class="black-link text-button"
              @click.stop="$emit('send-qr-notification', collection)"
            >
              <v-icon class="mr-1" color="primary">{{ mdiSendOutline }}</v-icon>
              QR-Code Mail
            </a>
          </template>

          <a
            class="text-button"
            @click="handleDuplicationRequest"
          >
            <v-icon color="primary" class="mr-1">{{ mdiContentDuplicate }}</v-icon>
            Duplizieren
          </a>

          <a
            v-if="this.isEditable"
            class="text-button"
            @click="$emit('edit-collection')"
          >
            <v-icon color="primary" class="mr-1">{{ mdiPencil }}</v-icon>
            Bearbeiten
          </a>
          <a
            v-if="this.isExcludable"
            class="text-button"
            @click="$emit('exclude-collection')"
          >
            <v-icon color="primary" class="mr-1">{{ mdiTableRemove }}</v-icon>
            Abwählen
          </a>

          <a
            v-if="
              showButtons &&
              collection.currentlyActive &&
              showButtons &&
              showCloseButton &&
              (collection.organization === currentOrganization.url ||
                collection.location !== null)
            "
            class="text-button"
            @click="$emit('close-collection')"
          >
            <v-icon class="mr-1" color="primary">{{ mdiStop }}</v-icon>
            <template v-if="collection.end">Vorzeitig</template
            ><template v-else>Jetzt</template> beenden
          </a>
          <span v-if="false" class="locked-badge inherited"
            >(von Mutterorg. vorgeg.)</span
          >
        </div>
      </v-card-actions>
    </v-card>
    <template v-if="!collection.hasPassed && (hasSpendeApp || hasKollekteApp) && !inactiveForAllTargets">
      <template>
        <v-row justify="center">
          <v-dialog v-model="qrInfoDialog" scrollable width="600px">
            <v-card>
              <v-card-title>
                <span class="headline">Direktlink "{{ collection.name }}"</span>
              </v-card-title>
              <v-card-text>
                <p>
                  Der folgende Link und QR-Code führen zu diesem Zweck Ihrer
                  Organisation. Wenn dieser zeitlich abläuft oder noch nicht
                  aktiv ist, wird der Spender zu Ihrem ersten Zweck umgeleitet.
                  Hier finden Sie den allgemeinen QR-Code zu Ihrer
                  <router-link
                    v-if="hasKollekteApp"
                    :to="{
                      name: 'management.org.kollekteapp',
                      params: { id: this.currentOrganization.id },
                    }"
                    >kollekte.app</router-link
                  >
                  <span v-if="hasKollekteApp && hasSpendeApp"> und </span
                  ><router-link
                    v-if="hasSpendeApp"
                    :to="{
                      name: 'management.org.spendeapp',
                      params: { id: this.currentOrganization.id },
                    }"
                    >spende.app</router-link
                  >.
                </p>
                <p v-if="hasKollekteApp">
                  Link zu dieser Sammlung auf kollekte.app:<br /><a
                    :href="kollekteAppUrl"
                    target="_blank"
                    >{{ kollekteAppUrl }}</a
                  >
                </p>
                <p v-if="hasSpendeApp">
                  Link zu dieser Sammlung auf spende.app:<br /><a
                    :href="spendeAppUrl"
                    target="_blank"
                    >{{ spendeAppUrl }}</a
                  >
                </p>
                <v-expansion-panels multiple focusable>
                  <v-expansion-panel v-if="hasKollekteApp">
                    <v-expansion-panel-header>
                      QR-Code zu dieser Sammlung auf kollekte.app
                    </v-expansion-panel-header>
                    <v-expansion-panel-content eager>
                      <q-r-canvas
                        class="pt-4"
                        :options="optionsKollekteApp"
                        ref="kollekteAppQrCanvas"
                      ></q-r-canvas>
                      <p style="font-size: 0.9em">
                        Zum Speichern des Bildes rechte Maustaste auf das Bild
                        und "Speichern unter" drücken.
                      </p>
                    </v-expansion-panel-content>
                    <div
                      v-if="hasKollekteApp"
                      class="mt-2 full-width"
                    >
                      <v-btn
                        color="primary"
                        @click="downloadQrCode('kollekteAppQrCanvas')"
                      >
                        <v-icon left>{{ mdiDownload }}</v-icon>
                        QR-Code herunterladen
                      </v-btn>
                    </div>
                  </v-expansion-panel>
                  <v-expansion-panel v-if="hasSpendeApp">
                    <v-expansion-panel-header>
                      QR-Code zu dieser Sammlung auf spende.app
                    </v-expansion-panel-header>
                    <v-expansion-panel-content eager>
                      <q-r-canvas
                        class="pt-4"
                        :options="optionsSpendeApp"
                        ref="spendeAppQrCanvas"
                      ></q-r-canvas>
                      <p style="font-size: 0.9em">
                        Zum Speichern des Bildes rechte Maustaste auf das Bild
                        und "Speichern unter" drücken.
                      </p>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                  <div
                    v-if="hasSpendeApp"
                    class="mt-2 full-width"
                  >
                    <v-btn
                      color="primary"
                      @click="downloadQrCode('spendeAppQrCanvas')"
                    >
                      <v-icon left>{{ mdiDownload }}</v-icon>
                      QR-Code herunterladen
                    </v-btn>
                  </div>
                </v-expansion-panels>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" text @click="qrInfoDialog = false">
                  Schließen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
    </template>

    <v-dialog
      v-model="prioInfoDialog"
      max-width="500"
      scrollable
    >
      <v-card>
        <v-card-title class="headline">
          Priorität kurz erklärt
        </v-card-title>
        <v-card-subtitle>
          für <template v-if="this.deviceFilter === 'SP'">die </template>{{ this.deviceResolver(this.deviceFilter) }}
        </v-card-subtitle>
        <v-card-text v-if="this.deviceFilter === 'SP'">
          <p>An dieser Stelle können Sie einstellen mit welcher Priorität bzw. in welcher Reihenfolge
             (wenn mehrere Sammlungen angezeigt werden) Sammlungen auf der Spendensäule angezeigt werden.
              Wenn keine Priorität / Reihenfolge festgelegt wurde - wird immer der neueste Zweck angezeigt
               (das heißt, Sonntagskollekten vor Spendenzwecken).
          <p>Die Einstellung, mehrere Spendenzwecke anzuzeigen, finden Sie im Hauptmenü unter "Geräte" direkt bei jeder Spendensäule.</p>
        </v-card-text>
        <v-card-text v-else>

          <p>Die Priorität bestimmt die Reihenfolge der Sammlungszwecke in spende.app/kollekte.app.
             Innerhalb einer Priorität wird der zuletzt eingetragene Zweck zuerst angezeigt.
            <span v-if="false"> Innerhalb einer Priorität wird der
             zuletzt eingetragene Zweck zuerst angezeigt.</span></p>
          <p>
            Die voreingestellte Reihenfolge ist wie folgt:<br>
            1 – Hauptkollekten<br>
            2 – Nebenkollekten<br>
            3 – Freie Kollekten<br>
            4 – Spendenzwecke
          </p>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="prioInfoDialog = false"
          >
            schließen
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="evaluationDialog"
      transition="dialog-top-transition"
      scrollable
      width="44em"
    >
      <v-card elevation="0">
        <v-card-text>
          <EvaluationStats
            :evalCollectionPlan="evalCollectionPlan"
            :key="evaluationDialog"
          />
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click.stop.prevent="evaluationDialog = false"
            color="primary"
          >
            Schließen
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>

    <AddCashCollectionDialog
      v-model="cashCollectionDialog"
      :organization="currentOrganization"
      :collection="collection"
    />
  </div>
</template>

<script>
import moment from "moment"
import { mapGetters } from "vuex"
import { QRCanvas } from "qrcanvas-vue"
import config from "@/config"
import {
  ADD_COLLECTION_PLAN_EXTENSION,
  DELETE_COLLECTION_PLAN_EXTENSION,
  PATCH_COLLECTION_PLAN_EXTENSION,
  FETCH_ORGANIZATION_COLLECTION_PLANS
} from '@/store/action-types'
import { SET_ERROR_MESSAGE } from '@/modules/common/store/mutation-types'
import EvaluationStats from '@/components/revenue/EvaluationStats'
import OrganizationIcon from '@/components/icons/OrganizationIcon'
import DeviceIcon from '@/components/icons/DeviceIcon'
import AddCashCollectionDialog from '@/components/collection/AddCashCollectionDialog.vue'
import { DEVICE_CLASSES, DEVICE_TYPES } from '@/lib/device-db'
import { PERM_TYPE_READ_OTHER, READ_CPLAN } from '@/lib/permission-tools'
import {
  mdiInformation,
  mdiFormatListGroupPlus,
  mdiFormatListGroup,
  mdiCheckboxMarkedCirclePlusOutline,
  mdiMapMarker,
  mdiTable,
  mdiTableRemove,
  mdiClockStart,
  mdiClockEnd,
  mdiQrcode,
  mdiContentDuplicate,
  mdiPencil,
  mdiStop,
  mdiDownload,
  mdiSendOutline
} from "@mdi/js"
import { FEATURE_FLAG_KODI_392_SHOW_COLLECTION_PLAN_GROUP_EDITOR } from "@/feature-flags"

export default {
  name: "collection-card",
  emits: [
    'close-collection',
    'duplicate-collection',
    'edit-collection',
    'exclude-collection',
    'mark-as-collection-group-candidate',
    'unmark-as-collection-group-candidate',
    'duplicate-collection-plan-group',
    'send-qr-notification'
  ],
  props: {
    collection: {
      required: true,
    },
    currentOrganization: {
      required: true,
    },
    displayedDate: {
      required: false,
      default: null,
    },
    moreInfos: {
      required: false,
      default: false,
    },
    showButtons: {
      required: false,
      default: true,
    },
    showCloseButton: {
      required: false,
      default: true,
    },
    showPrioChooser: {
      required: false,
      default: false
    },
    deviceFilter: {
      required: false,
      default: 'all'
    },
    relevantDevices: {
      type: Set,
      required: false,
      default: () => new Set(),
    },
    isEligibleForCollectionPlanGroup: {
      type: Boolean,
      required: false,
      default: false
    },
    isCollectionPlanGroupCandidate: {
      type: Boolean,
      required: false,
      default: false
    },
    isArchived: {
      type: Boolean,
      required: false,
      default: false
    },
    dialog: null,
    notificationContacts: {
      type: Array,
      required: false,
      default: () => []
    },
  },
  data: () => ({
    FEATURE_FLAG_KODI_392_SHOW_COLLECTION_PLAN_GROUP_EDITOR,
    qrInfoDialog: false,
    prioSelection: 4,
    prioInfoDialog: false,
    cashCollectionDialog: false,
    evaluationDialog: false,
    prioLoader: false,
    shortname2Settingsname: { WS: 'spende.app', WA: 'kollekte.app', SP: 'SP', KS: 'SP' },
    evalCollectionPlan: null,
    PERM_TYPE_READ_OTHER,
    READ_CPLAN,
    mdiInformation,
    mdiFormatListGroupPlus,
    mdiFormatListGroup,
    mdiCheckboxMarkedCirclePlusOutline,
    mdiMapMarker,
    mdiTable,
    mdiTableRemove,
    mdiClockStart,
    mdiClockEnd,
    mdiQrcode,
    mdiContentDuplicate,
    mdiPencil,
    mdiStop,
    mdiDownload,
    mdiSendOutline
  }),
  components: {
    DeviceIcon,
    QRCanvas,
    EvaluationStats,
    OrganizationIcon,
    AddCashCollectionDialog,
  },
  computed: {
    ...mapGetters("organization", { allOrganizations: "allOrganizations", organization: "currentOrganization" }),
    ...mapGetters("collectionPlan", ['collectionPlanGroupsByUrl']),
    priority: function () {
      const thisDevice = this.shortname2Settingsname[this.deviceFilter] ?? null
      // eslint-disable-next-line camelcase
      if (this.currentExtension !== null) {
        return (this.currentExtension.settings_local?.[thisDevice]?.display?.order?.priority ?? 1) - 1
      } else {
        return this.defaultPrio()
      }
    },
    filteredCollectionTargetsAll () { // FIXME: Maybe use organization relevant devices?
      const orgaDevices = this.relevantDevices
      const resVal = []
      this.collection.targets.forEach((target) => {
        if (Object.entries(DEVICE_TYPES).filter(
          ([deviceType, ddbEntry]) => ddbEntry.deviceClass === target
        ).some(
          ([deviceType, ddbEntry]) => orgaDevices.has(deviceType))
        ) {
          resVal.push(target)
        }
      })
      return resVal
    },
    filteredCollectionTargetsIncluded: function () {
      return this.filteredCollectionTargetsAll.filter((target) => !this.collection.excludedFor[this.organization.url].includes(target))
    },
    filteredCollectionTargetsExcluded: function () {
      return this.filteredCollectionTargetsAll.filter((target) => this.collection.excludedFor[this.organization.url].includes(target))
    },
    inactiveForAllTargets: function () {
      return this.filteredCollectionTargetsIncluded.length === 0
    },
    currentExtension: function () {
      const thisDevice = this.shortname2Settingsname[this.deviceFilter] ?? null
      if (thisDevice === null) {
        return null
      }
      for (const ext of this.collection.extensions) {
        if (ext.device_class === this.deviceFilter) {
          if (ext.settings_local?.[thisDevice]?.display?.order?.priority) {
            return ext
          }
        }
      }
      return null
    },
    colEndDate: function () {
      return this.collection.end === null
        ? null
        : moment(this.collection.end).format("llll")
    },
    colStartDate: function () {
      return this.collection.start === null
        ? null
        : moment(this.collection.start).format("llll")
    },
    colPercentageComplete: function () {
      if (this.colEndDate === null || this.colStartDate === null) {
        return null
      }
      const start = moment(this.collection.start)
      const end = moment(this.collection.end)
      const now = moment()
      const percentageComplete = (now - start) / (end - start) * 100
      return (Math.round(percentageComplete * 100) / 100)
    },
    colSundayDate: function () {
      if (!this.displayedDate) {
        return ""
      }
      return moment(this.displayedDate.day).format("dd., LL")
    },
    relEndTime: function () {
      return moment(this.collection.end).from(this.$store.state.now, true)
    },
    kollekteAppUrl () {
      return (
        config.kollekteApp.url +
        "/p/" +
        this.currentOrganization.id +
        "#c/" +
        this.collection.id
      )
    },
    spendeAppUrl () {
      return (
        config.spendeApp.url +
        "/o/" +
        this.currentOrganization.id +
        "#c/" +
        this.collection.id
      )
    },
    optionsKollekteApp () {
      return {
        size: "480",
        data: this.kollekteAppUrl,
      }
    },
    optionsSpendeApp () {
      return {
        size: 480,
        data: this.spendeAppUrl,
      }
    },
    hasSpendeApp () {
      return this.effectiveOrganizationSettings?.override?.enableWS || false
    },
    hasKollekteApp () {
      return this.effectiveOrganizationSettings?.override?.enableWA || false
    },
    isEditable () {
      return (this.collection.organization === this.currentOrganization.url ||
        this.collection.location !== null) &&
        this.displayedDate &&
        moment(this.displayedDate.start) > this.$store.state.now
    },
    isExcludable () {
      return (this.collection.organization !== this.currentOrganization.url &&
        this.collection.location === null) && (
        this.displayedDate && moment(this.displayedDate.start) > this.$store.state.now
      )
    },
    effectiveOrganizationSettings () {
      return this.$store.getters["settings/effectiveSettingsForUrl"](this.currentOrganization.url)
    },
    allowCashPayments () {
      // eslint-disable-next-line camelcase
      return this.effectiveOrganizationSettings.features?.allow_cash_payments || false
    },
    isCollectionPlanGroupParent () {
      return !!this.collectionPlanGroupsByUrl[this.collection.url]
    },
    shouldShowDetailsButton () {
      // return !FEATURE_FLAG_KODI_392_SHOW_COLLECTION_PLAN_GROUP_EDITOR && !this.isCollectionPlanGroupParent
      return true
    },
    isCollectionExcludedForCurrentOrganization () {
      return !!this.collection.excludedFor[this.organization.url].length
    },
    collectionDisplayType () {
      if (this.collection.type === 'donation') {
        return 'Spendenzweck'
      }

      if (this.collection.collection_variant === 'inner') {
        return 'Hauptkollekte'
      }

      if (this.collection.collection_variant === 'outer') {
        return 'Nebenkollekte'
      }

      return 'Freie Kollekte'
    },
  },
  watch: {
    deviceFilter: async function (newVal) {
      if (newVal && this.showPrioChooser) {
        this.prioSelection = this.priority
      }
    },
    collection: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          this.prioSelection = this.priority
        }
      }
    },
  },
  methods: {
    async openEvaluationDialog (eC) {
      this.evalCollectionPlan = eC
      this.evaluationDialog = true
    },
    snack (message) {
      this.$store.commit(SET_ERROR_MESSAGE, { message })
    },
    hasPermission (permission) {
      return this.$store.getters['user/hasPermission'](this.currentOrganization?.url, permission)
    },
    async savePrioExtension () {
      this.prioLoader = true
      // find out if there already is a settings for this compo
      if (this.thisDevice === null) {
        this.snack()
      }
      if (this.currentExtension === null) {
        await this.addPrioExtension()
      } else if (this.deviceFilter === 'SP' && this.prioSelection === 3) {
        await this.deletePrioExtensionObject(this.currentExtension.id)
      } else {
        await this.patchPrioExtension(this.currentExtension)
      }
      await this.$store.dispatch('collectionPlan/' + FETCH_ORGANIZATION_COLLECTION_PLANS, this.currentOrganization.url)
    },
    async addPrioExtension () {
      try {
        const thisDeviceName = this.shortname2Settingsname[this.deviceFilter] ?? null
        await this.$store.dispatch('collectionplanextension/' + ADD_COLLECTION_PLAN_EXTENSION, {
          plan: this.collection.url,
          organization: this.currentOrganization.url,
          location: null,
          device_class: this.deviceFilter,
          meta: {},
          settings_local: { [thisDeviceName]: { display: { order: { priority: (this.prioSelection + 1) } } } },
        })
      } finally {
        this.prioLoader = false
      }
    },
    async patchPrioExtension (extension) {
      if (!extension) {
        return false
      }
      this.prioLoader = true
      try {
        const thisDeviceName = this.shortname2Settingsname[this.deviceFilter] ?? null
        extension.settings_local[thisDeviceName] = { display: { order: { priority: this.prioSelection + 1 } } }
        await this.$store.dispatch('collectionplanextension/' + PATCH_COLLECTION_PLAN_EXTENSION, {
          id: extension.id,
          data: extension,
        })
      } finally {
        this.prioLoader = false
      }
    },
    async deletePrioExtensionObject (id) {
      await this.$store.dispatch('collectionplanextension/' + DELETE_COLLECTION_PLAN_EXTENSION, id)
    },
    thisDevice: function () {
      return this.shortname2Settingsname[this.deviceFilter] ?? null
    },
    defaultPrio: function () {
      if (this.deviceFilter === undefined) {
        return 4
      }
      if (this.deviceFilter === 'WS' || this.deviceFilter === 'WA') {
        if (this.collection.type === 'donation') {
          return 3
        } else {
          if (this.collection.collection_variant === 'outer') {
            return 1
          } else if (this.collection.collection_variant === 'x') {
            return 2
          } else if (this.collection.collection_variant === 'inner') {
            return 0
          } else {
            return 3
          }
        }
      } else {
        return 3
        /* if (this.collection.type === 'donation') {
          return 0
        } else {
          if (this.collection.collection_variant === 'outer') {
            return 1
          } else if (this.collection.collection_variant === 'x') {
            return 2
          } else if (this.collection.collection_variant === 'inner') {
            return 3
          } else {
            return 3
          }
        } */
      }
    },
    devicesToListResolver: function (devices) {
      const result = []
      for (const device in devices) {
        result.push(this.deviceResolver(devices[device]))
      }
      return result
    },
    deviceResolver: function (deviceClass) {
      return DEVICE_CLASSES[deviceClass] ?? "Unbekannt"
    },
    markAsCollectionGroupCandidate () {
      this.$emit('mark-as-collection-group-candidate', this.collection)
    },
    unmarkAsCollectionGroupCandidate () {
      this.$emit('unmark-as-collection-group-candidate', this.collection)
    },
    handleDuplicationRequest () {
      if (this.isCollectionPlanGroupParent) {
        this.$emit('duplicate-collection-plan-group')
        return
      }
      this.$emit('duplicate-collection')
    },
    downloadQrCode (qrRef) {
      const canvasEl = this.$refs[qrRef].$el

      const downloadLink = document.createElement('a')

      downloadLink.download = this.collection.name + '.png'
      downloadLink.href = canvasEl.toDataURL()
      downloadLink.click()
      downloadLink.remove()
    },
  },
}
</script>

<style lang="stylus" scoped>
.moreInfos {
  .v-icon {
    font-size: 1em;
    margin-top: -3px;
  }
}

.collection-card {
  &.currently-active {
    border: var(--clr-environment) solid 3px;
  }

  &.fully-disabled {
    opacity: 0.4;
  }
}

.fontstyle {
  font-size: 1em;
  color: rgba(0, 0, 0, 0.87);
}

.card-actions
  border-top: 1px solid var(--clr-environment)
  width: 100%
  padding-left: 0.6em
  padding-top: 0.4em
  padding-bottom: 0.4em
  a
    padding-left: 0.5em
    padding-right: 0.5em
    margin: 0
    display: inline-block
    min-width: 0
    border-radius: 4px
    text-transform: uppercase
    color: primary
  a:hover
    background: #f4f4f4
  .black-link
    color: black

.headline-container,
.full-width
  width: 100%
</style>
